@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url(./assets/fonts/DMSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'DM Sans';
  font-weight: 700;
  src: local('DM Sans'), url(./assets/fonts/DMSans-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'DM Sans';
  font-weight: 500;
  src: local('DM Sans'), url(./assets/fonts/DMSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'DM Mono';
  src: local('DM Mono'), url(./assets/fonts/DMMono-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'DM Mono';
  font-weight: 300;
  src: local('DM Mono'), url(./assets/fonts/DMMono-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'DM Mono';
  font-weight: 500;
  src: local('DM Mono'), url(./assets/fonts/DMMono-Medium.woff2) format('woff2');
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'DM Sans', 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
  max-width: 100%;
}

button,
a,
link {
  font-family: 'DM Sans' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
::-webkit-scrollbar-track {
  background: #888;
}
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiAvatar-colorDefault {
  color: #fff !important;
  font-size: 14px !important;
}
